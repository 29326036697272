/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, image, meta, keywords, title, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  // const {seo: defaultSeo} = defaults.childMdx.frontmatter;
  // const metaDescription = description || defaultSeo.description
  // const metaTitle = title || defaultSeo.title
  const metaDescription = site.siteMetadata.description;
  const metaTitle = site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`Canlis`}
      titleTemplate={metaTitle}
      meta={[
        {
          name: `description`,
          content: "metaDescription",
        },
        {
          name: `viewport`,
          content:
            "width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover",
        },
        {
          property: `og:title`,
          content: `${metaTitle}`,
        },
        {
          property: `og:url`,
          content: `https://community.canlis.com/`,
        },
        {
          property: `og:image`,
          content: "https://community.canlis.com/media/ccc_share.jpg",
        },
        {
          property: `twitter:image`,
          content: "https://community.canlis.com/media/ccc_share.jpg",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `canlis`,
        },
        {
          name: `twitter:title`,
          content: `${metaTitle}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  pathname: PropTypes.string,
};

export default SEO;
